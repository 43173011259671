import {Helmet} from "react-helmet";

const Privacy = () => {


    return (
        <>
            <Helmet>
                <title>Privacy Policy</title>
            </Helmet>

            <div style={{width: '100%', height: '80%', borderRadius: '10px', border: '1px solid black'}}>
                <iframe style={{border: 0, padding: '4px'}} height={"100%"} width={"100%"} src={'/TnC/Privacy.html'} title={"Privacy Policy"}></iframe>
            </div>
        </>
    )
}

export default Privacy;