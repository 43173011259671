import {Helmet} from "react-helmet";

const Terms = () => {


    return (
        <>
            <Helmet>
                <title>Terms and Conditions</title>
            </Helmet>

            <div style={{width: '100%', height: '80%', borderRadius: '10px', border: '1px solid black'}}>
                <iframe style={{border: 0, padding: '4px'}} height={"100%"} width={"100%"} src={'/TnC/Terms.html'} title={"Terms and Conditions"}></iframe>
            </div>
        </>
    )
}

export default Terms;