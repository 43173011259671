import React from 'react'
import {BORDER_RADIUS, LOGO_MOBILE} from "../../../../Helper";
import {Link, NavLink} from "react-router-dom";
import {Divider, Grid} from "@mui/material";
import Button from "@mui/material/Button";

class Header extends React.Component {

    render() {
        return (
            <Grid className={'mb-2'} container spacing={3}>
                <Grid item xs={2}>
                    <Link style={{marginRight: '0.16rem'}} className={"navbar-brand"} to={"/"}>
                        <img src={LOGO_MOBILE} style={{height: '37px'}}/>
                    </Link>
                </Grid>
                <Grid item xs>
                    <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                        <div className={'custom-card'}>
                            <Link className={'text-custom-yellow font-weight-bold'} style={{textDecoration: 'none'}} to={'/signup'}>Sign Up</Link>
                            <Divider className={"m-2"} color={"white"} orientation="vertical" flexItem/>
                            <Link className={'text-custom-yellow font-weight-bold'} style={{textDecoration: 'none'}} to={'/login'}>
                                <Button className={'custom-button'} variant={"contained"} color="warning" sx={{py: 0, color: 'white', borderRadius: BORDER_RADIUS
                            }} size={'small'}>
                                    Login
                                </Button>
                            </Link>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default Header;