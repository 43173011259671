import React from "react";
import {Routes, Route, useLocation, useNavigate} from "react-router-dom";

import Index from "./App/Pages";
import NotFound from "./App/Pages/404";
import Support from "./App/Pages/Support";
import Bonus from "./App/Pages/Affiliate/Bonus";

import UserStat from "./App/Components/User/Stat";
import GameStat from "./App/Components/Game/Stat";
import UserSetting from "./App/Components/User/Setting";
import Account from "./App/Components/User/Account";
import Leaderboard from "./App/Pages/Leaderboard";

import Crash from "./App/Games/Crash";
import ReferralEarners from "./App/Pages/ReferralEarners";
import WithdrawBonus from "./App/Pages/Bonus/WithdrawBonus";
import Login from "./App/Pages/Authentication/Login";
import Register from "./App/Pages/Authentication/Register";
import Forgot from "./App/Pages/Authentication/Forgot";
import AuthTemplate from "./App/Pages/Authentication/AuthTemplate";
import Refer from "./App/Pages/Affiliate/Refer";
import Terms from "./App/Pages/Support/Terms";
import Privacy from "./App/Pages/Support/Privacy";
import Responsible from "./App/Pages/Support/Responsible";

function Router(props) {
    let location = useLocation();
    const navigate = useNavigate();
    let background = location.state && location.state.background;

    const {t} = props;

    return (
        <>
            <Routes  location={background || location}>
                <Route exact path="/" element={<Index navigate={navigate} t={t} location={location} content={<Crash t={t}/>}/>}/>
                <Route path="/signup" element={<AuthTemplate navigate={navigate} t={t} content={<Register t={t}/>}/>}/>
                <Route path="/login" element={<AuthTemplate navigate={navigate} t={t} content={<Login t={t}/>}/>}/>
                <Route path="/forgot" element={<AuthTemplate navigate={navigate} t={t} content={<Forgot t={t}/>}/>}/>
                <Route path="/leaderboard" element={<Index navigate={navigate} t={t} content={<Leaderboard t={t}/>}/>}/>
                <Route path="/earners" element={<Index navigate={navigate} t={t} content={<ReferralEarners t={t}/>}/>}/>
                <Route path="/support" element={<Index navigate={navigate} t={t} content={<Support t={t}/>}/>}/>
                <Route path="/terms" element={<Index navigate={navigate} t={t} content={<Terms/>}/>}/>
                <Route path="/responsible" element={<Index navigate={navigate} t={t} content={<Responsible/>}/>}/>
                <Route path="/privacy" element={<Index navigate={navigate} t={t} content={<Privacy/>}/>}/>
                <Route path="/referral" element={<Index navigate={navigate} t={t} content={<Refer t={t}/>}/>}/>
                <Route path="/account" element={<Index navigate={navigate} t={t} content={<Account t={t}/>}/>}/>
                <Route path="/bonus" element={<Index navigate={navigate} t={t} content={<WithdrawBonus t={t} />} />} />
                <Route path="/win/:id" element={<AuthTemplate navigate={navigate} t={t} content={<Bonus navigate={navigate} params={location} t={t}/>}/>}/>
                <Route path="/setting" element={<Index navigate={navigate} t={t} content={<UserSetting t={t}/>}/>}/>
                <Route path="/user/:id" element={<Index navigate={navigate} t={t} content={<UserStat t={t} params={location}/>}/>}/>
                <Route path="/game/:id" element={<Index navigate={navigate} t={t} content={<GameStat t={t} params={location}/>}/>}/>
                <Route path="*" element={<NotFound/>}/>
            </Routes>
        </>
    );
}

export default Router;